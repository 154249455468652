import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const ClientSupportPage = () => (
  <div>
      <SEO 
        title="Website Hosting 24x7 Client Support Service" 
        description="A 24x7 client support service service and a 1–hour response warranty are offered with each package."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-2">
          <div className="text-center mx-auto">
              <p className="section-title">Website Hosting <span className="block text-indigo-600 xl:inline">24x7 Client Support Service</span> <span role="img" aria-label="emoji">✨ </span></p>
              <p className="section-after-title">
              A 24x7 client support service service and a 1–hour response warranty are offered with each package.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title text-indigo-800">
              Customer Support Channels
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/ticket-response-time.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A 1–hour Response Guarantee</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        We offer a 1–hour reply warranty with all our website hosting packages, which means every single 
                        assistance question you email will undoubtedly be answered to in less than 1 hour maximum. Our 
                        support company members are taught to promptly take care of any issue and to give you detailed 
                        guidance on any concern that you may have.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/quality-support-service.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">A Top–quality Support Service</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Our tech support service is offered by a group of experienced techs that are able to respond to 
                        your inquiry right away. You’ll be able to speak with the tech support team team by starting a 
                        tech support ticket from your Web Control Panel or by posting us an email. On business days, 
                        you may also call us on the phone or make use of the online chatting solution.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/help-center.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">An Intuitive Help Section</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Sheephostingbay’s Control Panel presents a wide–ranging know–how base that’s included in all areas. 
                        In each section, you will find a Help control key that will load for you a selection of 
                        step–by–step courses on how to work with a selected element or perform a specific 
                        action. Additionally, we have produced course films to enable you to get a better 
                        idea of the Control Panel’s interface.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default ClientSupportPage
